
import React from 'react';

import Contact from './Contact';



const Home = () => {
 



    return (
        <section>



    
    <div className="container-fliud bg-1 pt-0" id="home">

    <div className="robos d-flex align-items-center">
    <div className="circle2 d-flex align-items-center">
        <div className="circle3">
             <div className="robo-img">
            <img src="img/robo.gif" alt="" />
        </div>
        <div className="robo-tittle">
                  <h4>VOICEMATE AMPLIFY</h4>
                  <p>No Code Sales and Engagement Buddy</p>
        </div>
        </div>
    </div>
       
    </div>
          {/* <div className="robo">
              <div className="robo-img text-center">
                  <img src="img/robo.gif" alt="" />
              </div>
              <div className="robo-tittle">
                  <h4>VOICEMATE AMPLIFY</h4>
                  <p>No Code Sales and Engagement Buddy</p>
              </div>
          </div> */}
      </div>

      <div className="bg-2 pb-0" id="challenges">
          <div className="container">
          <div className="heading-challenges pb-3">
              <h4>Top Challenges in <strong>B2C LEAD GENERATION</strong></h4>
          </div>
          
          <div className="b2c">
              <div className="btc-1 ms-lg-5 ps-lg-5 ms-0 ps-0 mb-lg-0 pb-50">
                  <img src="img/hcdg.gif" className="img-fluid wow bounceInRight"  alt="" data-wow-iteration="1" data-wow-duration="1.5s" />
                <span className="one">01</span>
              </div>
          </div>
          </div>
    </div>
    <div className="bg-16 pb-5" id="challenges">
          <div className="container">
          <div className="b2c">
              <div className="btc-2 me-lg-5 pe-lg-5 me-0 pe-0 mb-lg-0 pb-50"  >
                <span className="two">02</span>
                <img src="img/opp.gif" className="img-fluid wow bounceInRight" alt="" data-wow-iteration="1" data-wow-duration="2s" />
            </div>
            <div className="btc-3 ms-lg-5 ps-lg-5 ms-0 ps-0 mb-lg-0 pb-50"  >
                <img src="img/rls.gif" className="img-fluid wow bounceInRight" alt="" data-wow-iteration="1" data-wow-duration="2.2s" />
                <span className="three">03</span>

            </div>
             <div className="btc-4 me-lg-5 pe-lg-5 me-0 pe-0 " >
                <span className="four">04</span>
                <img src="img/htc.gif" className="img-fluid wow bounceInRight" alt=""  data-wow-iteration="1" data-wow-duration="2.3s"/>
            </div>
          </div>
          </div>
      </div>

      <div className="container-fluid bg-3 ambg" id="about">
          <div className="container">
              <div className="heading-amplify">
                  <h4>What is <strong>VoiceMate Amplify?</strong></h4>
                  <hr className="hr" style={{borderTop: '6px solid #00EAFA', width: '25%', margin: 'auto', marginBottom: '20px'}} />
                  <p>A No code sales automation platform with <strong> Voice based conversational assistant 
                      to generate<br /> leads from Sales engagement</strong> between business and a pool of customer
                       data. VoiceMate<br /> Amplify helps to generate leads in <strong> one click</strong> and <strong>cold 
                       call campaigns</strong> straight from<br /> your browser in <strong>mere seconds.</strong>
                </p>
              </div>
              <div className="img-amplify">
                  <img src="img/amplify.gif" className="img-fluid" alt="" />
              </div>
          </div>
      </div>

      

      <div className="container-fluid bg-3 workbg" id="services">
        <div className="container">
            <div className="heading-amplify">
                <h4><strong>How it works?</strong></h4>
            </div>
            <div className="img-amplify">
                <img src="img/how it work.gif" className="img-fluid" alt="" />
            </div>
        </div>
    </div>

    <div className="container-fluid bg-3 solubg" id="features">
        <div className="container">
            <div className="heading-amplify">
                <h4><strong>Solution Features</strong></h4>
            </div>
            <div className="img-amplify solution_bg">
                <img src="img/sulutions features.gif" className="img-fluid" alt="" />
            </div>
        </div>
    </div>

    <Contact />

      

    </section>  
        
    )

};

export default Home;


   

    

    

    
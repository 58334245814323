

import React from 'react';


const Footer = () => {
 return (
<section>

        <div className="footer bg-5 footer">
        <div className="container">
        <div className="getintouch">
            <h4>GET IN TOUCH</h4>
            <a href="tel:84939469074">+84 93 946 90 74</a><br />
            <a href="mailto:info@thevoicemate.com">info@thevoicemate.com</a>
        </div>
        <div className="address">
            <p>Chính sách Bảo mật / Bản quyền © 2021 VOICEMATE. Đã đăng ký Bản quyền <br />
                Thông tin liên hệ <br />
                Công Ty TNHH Công Nghệ VOICEMATE <br />
                Giấy chứng nhận đăng ký kinh doanh số 0316715597 <br />
                Do Sở KH & ĐT Thành phố Hồ Chí Minh cấp ngày 17/02/2021</p>
        </div>
        <div className="f-logo">
            <img src="img/moit Logo.webp" width="188" height="71" alt="" />
        </div>
        <div className="credit">
            <p style={{paddingTop: '10px', color: '#fff'}}>© 2021 by TheVoicemate</p>
        </div>
        </div>
    </div>

     <div className="icon-bar">
        <a href="https://www.facebook.com/thevoicemate" className="facebook"><i className="fa fa-facebook"></i></a> 
        <a href="https://www.instagram.com/voice_mate/" className="instagram"><i className="fa fa-instagram"></i></a> 
        <a href="https://www.linkedin.com/company/voicemate/" className="linkedin"><i className="fa fa-linkedin"></i></a>
      </div>

      <button  class="chat" >
      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" >
      <path d="M16 2C8.28 2 2 7.8 2 14.93a12.144 12.144 0 001.696 6.15l-1.668 7.51A1.16 1.16 0 003.63 29.9l6.914-3.072A14.835 14.835 0 0016 27.861c7.72 0 14-5.8 14-12.93S23.72 2 16 2zm4.508 16.32h-9.016a1.16 1.16 0 010-2.32h9.016a1.16 1.16 0 010 2.32zm0-4.638h-9.016a1.16 1.16 0 010-2.318h9.016a1.16 1.16 0 110 2.318z" fill="currentColor" fill-rule="nonzero"></path></svg>
      </button>


</section>
         




    )

};

export default Footer;






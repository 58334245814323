
import React from 'react';
import Header from './pages/Header';
import Home from './pages/Home';
import Footer from './pages/Footer';


function App() {
  return (
    <>
    <Header />
      <Home />
   <Footer />
   </>
  );
}

export default App;

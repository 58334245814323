import React, {useState} from 'react';
import axios from 'axios';
// import 'react-toastify/dist/ReactToastify.css';
import { Button } from 'rsuite';
import 'rsuite/dist/styles/rsuite-default.css'; 




const Contact = () => {
  const [msg, setMsg] = useState (null);
  const [errmsg, setErrMsg] = useState (null);

  
  const [loading, setLoading] = useState(false);
  

  const url = "https://contactforms.thevoicemate.com/contact-us/ "
 


  const [userRegistration, setUserRegistration] = useState({
      name: "",
      email: "",
      phone: "",
      subject: "",
      address: "",
      language: "en",
      identifier: "Voicemate Amplify"
  });


  const handleInput = (e) => {

      const name = e.target.name;
      
      const value = e.target.value;
     
      setUserRegistration({ ...userRegistration, [name]: value })
    
      
  }

  const [records, setRecords] = useState([]);

  const handleSubmit =  (e) => {
    e.preventDefault();
  

      const newRecord = { ...userRegistration}
      setRecords([ ...records, newRecord]);
     

     
      setLoading(true);
      
       axios.post(url, {
      name: userRegistration.name,
      email: userRegistration.email,
      phone: userRegistration.phone,
      subject: userRegistration.subject,
      address: userRegistration.address,
      language: userRegistration.language,
      identifier: userRegistration.identifier
    
      })
      
     .then(function (response) {
      setLoading(false);
      if(response.status === 200){
        console.log(response);
        setMsg(response.data.msg)
        setLoading(false);   
        setUserRegistration({name:"", email:"", phone: "", language: "",  identifier: "Voicemate Amplify", subject: "", address: ""})


      }
     
      
  })
      .catch(function (error) {
          setLoading(false);
          console.log(error.response);
          console.log(error.request);
          console.log(error);
          setErrMsg(error.response.data.msg)            
   
       });
            

        
  }




  return (
    
  <section>
    <div className="container-fluid bg-4" id="contact">
        <div className="container form-container">
            <div className="heading-contact">
                <h4>CONTACT US</h4>
            </div>
            {/* {(loading)? 
            <Loader  backdrop content="loading..." vertical />
           :null} */}
            <div className="col-ms-8 col-lg-7 form">
            <form onSubmit={(e)=>handleSubmit(e)} >
                <div className="form-row row">
                    <div className="form-group col-lg-6 mb-3">
                      <label className="f-700">Name</label>
                      <input type="text" value={userRegistration.name} onChange={handleInput} 
                       className="form-control" name="name"   placeholder="Name" required />
                    </div>
                    <div className="form-group col-lg-6 mb-3">
                    <label className="f-700">Email</label>

                    <input type="email"
                    name="email"
                    value={userRegistration.email} onChange={handleInput} 
                     className="form-control" placeholder="Email" required />
                  </div>
                   
                  </div>
                <div className="form-row row">
                  
                  <div className="form-group col-lg-6 mb-3">
                  <label className="f-700">Phone</label>

                    <input type="number" className="form-control"
                    name="phone"
                    value={userRegistration.phone} onChange={handleInput} 
                     placeholder="Phone" required />
                  </div>
                  <div className="form-group col-lg-6 mb-3">
                  <label className="f-700">Enquiry</label>
                      <input type="text" className="form-control" 
                      name="subject"
                      value={userRegistration.subject} onChange={handleInput} required 
                       placeholder="Enquiry" />
                    </div>
                </div>
                <div className="form-row row">
                    <div className="form-group col-lg-12 mb-3">
                    <label className="f-700">Address</label>

                      <input type="text" className="form-control" 
                      name="address"
                      value={userRegistration.address} onChange={handleInput} 
                       placeholder="Address"  />
                    </div>
                    <div className="form-group col-lg-6 mb-3">
                    <input type="hidden" className="form-control" 
                      name="language"
                      value={userRegistration.language} onChange={handleInput} 
                       placeholder="language"  />
                    </div>
                    { msg &&
                  <div class="alert alert-success">
                  {msg}
                  </div>
                  } 

               

                      { errmsg &&
                        <div class="alert alert-danger">
                  {errmsg}
                  </div>

                      } 
                    <div className="form-group col-lg-12 mb-3">
                      <input type="hidden" className="form-control" 
                      name="identifier"
                      value={userRegistration.identifier} onChange={handleInput} 
                       placeholder="identifier"  />
                    </div>
                    </div>
                
               <center><Button type="submit"
                loading={loading}
                   appearance="primary">Submit</Button></center> 
                  
                    {/* <ToastContainer /> */}
                   
              </form>
              </div>
        </div>
    </div>
  </section>
  )


};

export default Contact;


   


import React from 'react';
import {Link} from 'react-router-dom';


const Header = () => {

    return(

        <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <a className="navbar-brand" href="#">
        <p style={{margin: '0', color : 'white', fontSize: '13px'}}>Powered By:</p>

              <img src="img/logo.webp" alt="" width="185" height="104" style={{marginTop: '-15px' }} alt="logo" />
          </a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-center">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#home">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#about">About</a>
              </li>
               <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#challenges">Challenges</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#services">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#features">Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="#contact">Contact</a>
              </li>
            </ul>
          </div>

          <div className="menu_icon d-none d-lg-block">
        <Link to="/" data-bs-toggle="modal" data-bs-target="#exampleModal"><img src="img/menuicon.png" alt="" /></Link>
          </div>

          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog ">
    <div class="modal-content bg_navi">
      <div class="modal-header">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 flex-column  align-items-center flex-grow-1">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">About</a>
              </li>
               <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Challenges</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Features</a>
              </li>
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">Contact</a>
              </li>
            </ul>
          </div>
      </div>
      
    </div>
  </div>
</div>
          
          
        </div>
        
      </nav>

    )
};

export default Header;




